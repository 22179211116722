<template>
  <div>
    <div class="ml-4">
      <div v-if="question.type === exercise_type_constant.TRUE_FALSE_NOT_GIVEN">
        <v-radio-group
          :value="question.answers[0].correct.toString()"
          readonly
          class="mt-0 pt-0"
        >
          <template v-for="(value, i) in optionAnswerTrueFalse">
            <div class="d-flex" :key="i">
              <p class="text-h5 mt-4 mr-2" style="color: #6495ed">
                {{ i | getAlphabetCharacterFromIndex }}.
              </p>
              <v-radio :label="value" :value="value"></v-radio>
            </div>
          </template>
        </v-radio-group>
      </div>
      <div v-if="question.type === exercise_type_constant.YES_NO_NOT_GIVEN ">
        <v-radio-group
          :value="question.answers[0].correct.toString()"
          readonly
          class="mt-0 pt-0"
        >
          <template v-for="(value, i) in optionAnswerYesNo">
            <div class="d-flex" :key="i">
              <p class="text-h5 mt-4 mr-2" style="color: #6495ed">
                {{ i | getAlphabetCharacterFromIndex }}.
              </p>
              <v-radio :label="value" :value="value"></v-radio>
            </div>
          </template>
        </v-radio-group>
      </div>
      <div v-if="question.type === exercise_type_constant.SINGLE_CHOICE">
        <v-radio-group
          :value="question.answers[0].correct.toString()"
          readonly
          class="mt-0 pt-0"
        >
          <template v-for="(option, i) in question.answers[0].option">
            <div class="d-flex" :key="i">
              <p class="text-h5 mt-1 mr-2 mb-0" style="color: #6495ed">
                {{ i | getAlphabetCharacterFromIndex }}.
              </p>
              <v-radio :label="option.value" :value="option.value"></v-radio>
            </div>
          </template>
        </v-radio-group>
      </div>
      <div v-if="question.type === exercise_type_constant.SINGLE_SELECT">
        <v-radio-group :value="question.answers[0].correct.toString()" readonly>
          <template v-for="(option, i) in question.answers[0].option">
            <div :key="i" class="d-flex pt-2">
              <p class="text-h5 mr-2 mb-0" style="color: #6495ed">
                {{ i | getAlphabetCharacterFromIndex }}.
              </p>
              <v-radio :label="option" :value="option"></v-radio>
            </div>
          </template>
        </v-radio-group>
      </div>
      <div v-if="question.type === exercise_type_constant.MULTIPLE_CHOICE">
        <template
          v-for="(option, i) in convertDisplayMultipleChoice(
            question.answers[0].option,
            question.answers[0].correct
          )"
        >
          <v-row align="center" :key="i">
            <div style="width: 20%" class="d-flex">
              <p class="text-h5 mt-5 mr-2" style="color: #6495ed">
                {{ i | getAlphabetCharacterFromIndex }}.
              </p>
              <v-checkbox
                readonly
                v-model="option.checked"
                :label="option.value"
              ></v-checkbox>
            </div>
          </v-row>
        </template>
      </div>
      <div
        v-if="[
            exercise_type_constant.LONG_ANSWER,
            exercise_type_constant.WRITING_NO_MARKING,
        ].includes(question.type)
        ">
        <h5 class="teal--text darken-1">
          (Weight:
          {{ getLabelByValueWeight(question.weight) }})
        </h5>
        <v-textarea filled disabled label="Writing"></v-textarea>
      </div>
      <div v-if="question.type === exercise_type_constant.FILE_UPLOAD">
        <b-form-file
          disabled
          class="mb-2"
          style="width: 40%"
          placeholder="File Upload"
        ></b-form-file>
      </div>
      <div v-if="question.type === exercise_type_constant.RECORDING">
        <b-form-file
          disabled
          class="mb-2"
          style="width: 40%"
          placeholder="Recording"
        ></b-form-file>
      </div>
      <div v-if="question.type === exercise_type_constant.INDIVIDUAL_SOUNDS">
        <b-form-file
          disabled
          class="mb-2"
          style="width: 40%"
          placeholder="Auto Marking Individual Sounds"
        ></b-form-file>
      </div>
      <div v-if="question.type === exercise_type_constant.WORD_STRESSES">
        <b-form-file
          disabled
          class="mb-2"
          style="width: 40%"
          placeholder="Auto Marking Word Stresses"
        ></b-form-file>
      </div>
      <div
        v-if="
          question.type === exercise_type_constant.INDIVIDUAL_SOUNDS_AND_WORD_STRESSES
        "
      >
        <b-form-file
          disabled
          class="mb-2"
          style="width: 40%"
          placeholder="Auto Marking Individual Sounds And Word Stresses"
        ></b-form-file>
      </div>
      <div v-if="question.type === exercise_type_constant.SENTENCE_STRESSES">
        <v-textarea
          label=""
          readonly
          solo
          v-model.trim="question.body.correct"
          rows="1"
          auto-grow
          style="max-width: 60%"
        ></v-textarea>
      </div>
      <div
        v-if="
          question.type === exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES
        "
      >
        <v-textarea
          label=""
          readonly
          solo
          v-model.trim="question.body.correct"
          rows="1"
          auto-grow
          style="max-width: 60%"
        ></v-textarea>
      </div>
      <div v-if="question.type === exercise_type_constant.SINGLE_CHOICE_EDITOR">
        <template v-for="(option, i) in question.answers[0].option">
          <div style="display: flex" :key="i" class="mt-6">
            <p class="text-h5 mt-6 mr-2" style="color: #6495ed">
              {{ i | getAlphabetCharacterFromIndex }}.
            </p>
            <v-checkbox
              class="mt-5"
              hide-details
              v-model="option.checked"
              readonly
            ></v-checkbox>
            <div style="width: 50%; display: inline-block">
              <label style="font-size: 16px">{{ "Option " + (i + 1) }}</label>
              <div
                  style="height: 40px; max-width: 500px; overflow: auto; border: 1px solid grey"
                  class="rounded p-1"
                  v-html="option.value">
              </div>
<!--              <EditorBalloon-->
<!--                :value="decodeHTMLForChineseCharacter(option.value)"-->
<!--                :disabled="true"-->
<!--              ></EditorBalloon>-->
            </div>
          </div>
        </template>
      </div>
      <div v-if="question.type === exercise_type_constant.WRITING_AUTO_MARKING">
        <v-textarea
          rows="2"
          dense
          no-resize
          filled
          disabled
          label="Writing Auto Marking"
        ></v-textarea>
      </div>
      <div v-if="question.type === exercise_type_constant.SHADOWING">
        <b-form-file
          disabled
          class="mb-2"
          style="width: 40%"
          placeholder="Shadowing"
        ></b-form-file>
      </div>
      <div v-if="question.type === exercise_type_constant.HSK_SENTENCE_SPEECH_ASSESSMENT">
        <b-form-file
            disabled
            class="mb-2"
            style="width: 40%"
            placeholder="HSK Sentence Speech Assessment"
        ></b-form-file>
      </div>
      <div v-if="question.type === exercise_type_constant.SENTENCE_COMPLETION">
        <div v-html="question.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import EditorBalloon from "@/components/ckeditor5/EditorBalloon";
import weightSupport from "../../Modal/Support/Question/weightSupport";
import phoneticSupport from "../../Modal/Support/Question/phoneticSupport";
import constantTestBuilder from "../../mixins/Support/constantTestBuilder";

export default {
  name: "QuestionType",
  components: {
    // EditorBalloon,
  },
  mixins: [
      weightSupport,
      phoneticSupport,
      constantTestBuilder
  ],
  props: ["question", "exercise"],
  data() {
    return {
      optionAnswerTrueFalse: ["True", "False", "Not Given"],
      optionAnswerYesNo: ["Yes", "No", "Not Given"],
    };
  },
  methods: {
    convertDisplayMultipleChoice(option, correct) {
      let data = [];
      for (let iO = 0; iO < option.length; iO++) {
        data[iO] = {
          value: option[iO],
          checked: false,
        };
        for (let iC = 0; iC < correct.length; iC++) {
          if (correct[iC].index === iO) {
            data[iO].checked = true;
          }
        }
      }
      return data;
    },
  },
};
</script>

<style></style>
